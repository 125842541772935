import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx'
// import { BrowserRouter } from 'react-router-dom'
import { HashRouter} from 'react-router-dom';
// import { MemoryRouter } from 'react-router-dom';
// import App from './App';
import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <HashRouter>
    <App />
  </HashRouter>
);

// root.render(
//   <MemoryRouter> {/* Use MemoryRouter instead of HashRouter */}
//     <App />
//   </MemoryRouter>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
